import {Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(ChartDataLabels, ...registerables);

export default class VisualizationChartBehaviour {
    /**
     * Id of the complete Content Element
     * @type {number}
     */
    id = 0;
    /**
     * The canvas of the current chart
     * @type {HTMLCanvasElement}
     */
    canvas = null;
    /**
     * Id of the current canvas
     * @type {string}
     */
    canvasId = '';
    /**
     * The current chart.js instance
     * @type {Chart}
     */
    chart = null;
    /**
     * 2D drawing context of the current canvas
     * @type {CanvasRenderingContext2D}
     */
    context2d = null;
    /**
     * Collection of chart labels
     * @type {Array}
     */
    labels = null;
    /**
     * Collection of chart data
     * @type {Array}
     */
    data = null;
    /**
     * Collection of chart colors
     * @type {null}
     */
    colors = null;
    /**
     * Currency unit used in legend
     * @type {string}
     */
    unit = '';
    /**
     * Type of the chart
     * @type {string}
     */
    type = '';

    // Labels on the chart
    labelFontSize = 16;
    labelFontColor = '#fff';
    labelLastClicked = null;

    /**
     * Creates a new customized visualization chart.
     * This is the initialization point.
     *
     * @param {number} id The numeric id that Contao assigned the element so it can be uniquely identified.
     * @param {Array} labels An array of chart.js compatible labels that are passed to the chart.js instance.
     * @param {Array} data An array of datapoints that are chart.js compatible - passed to the chart.js instance.
     * @param {Array} colors An array of colors that are used by chart.js to color the chart in the order of appearance.
     * @param {string} [unit=''] Optional String representing a unit like a currency or a length
     * @param {string} [type='doughnut'] Type of the chart. Refer to chart.js documentation for possible values.
     */
    constructor(id, labels, data, colors, unit, type = 'doughnut') {
        this.id = id;
        this.canvasId = 'visualization-chart__canvas-' + this.id;
        this.labels = labels;
        this.data = data;
        this.colors = colors;
        this.unit = unit;
        this.type = type;
        this.canvas = document.getElementById(this.canvasId); // HTMLNode of the canvas
        this.context2d = this.canvas.getContext('2d'); // the drawing context of the canvas
        this.unit = unit || ''; // Unit of the numeric values displayed on the chart
        // create the actual chart.js instance,
        this.chart = this.createChart();

        if (VisualizationChartBehaviour.requiresTooltipWorkaround()) {
            this.registerTooltipWorkaround();
        }

        this.registerCanvasClickHandler();
    }

    /**
     * Creates a new chart.js instance on the specified canvas element id
     */
    createChart() {
        let brandNewChart = new Chart(this.context2d, {
            type: this.type,
            options: {
                responsive: true,
                title: {
                    display: false
                },
                layout: {
                    autoPadding: true
                },
                plugins: {
                    datalabels: {
                        // Formatting or extend value output
                        formatter: (value) => {
                            return `${value} ${this.unit}`;
                        },
                        color: this.labelFontColor,
                        font: {
                            size: this.labelFontSize
                        }
                    },
                    tooltip: {
                        enabled: false,
                        external: this.customTooltipFactory,
                        callbacks: {
                            label: this.customLabelFactory
                        }
                    },
                    legend: {
                        display: false
                    }
                }
            },
            data: {
                labels: this.labels,
                datasets: [{
                    backgroundColor: this.colors,
                    data: this.data,
                    borderWidth: 0,
                }]
            }
        });
        brandNewChart.behaviour = this;
        return brandNewChart;
    }

    /**
     * Show a custom HTML tooltip to replace the on canvas one.
     */

    customTooltipFactory(context) {
        const tooltip = context.tooltip;
        // on firefox quantum on mac, wild flickering occurs by using this,
        // so we use the marked workaround further down
        if (VisualizationChartBehaviour.requiresTooltipWorkaround()) {
            return;
        }

        // Tooltip HTML template
        const behaviour = context.chart.behaviour;
        const tooltipTplElement = document.querySelector('#visualization-chart__canvas-' + behaviour.id + '__tooltip-tpl');
        const tooltipHtml = tooltipTplElement.innerHTML;

        // try to retrieve the current tooltip element
        let tooltipRef = document.getElementById('visualization-chart__canvas-'+ behaviour.id + '_tooltip');

        // if there is none, we need to create it first
        if (tooltipRef === null || tooltipRef === undefined) {
            const tooltipElem = document.createElement('div');
            tooltipElem.innerHTML = tooltipHtml;
            // set id to identify it for this chart
            tooltipElem.id = 'visualization-chart__canvas-'+ behaviour.id  + '_tooltip';
            tooltipElem.classList.add('visualization-chart');
            tooltipRef = document.body.appendChild(tooltipElem);
        }

        // Hide if no tooltip
        if (tooltip.opacity === 0) {
            tooltipRef.style.opacity = tooltip.opacity;
            return;
        }

        // create the tooltip text
        const tooltipContent = tooltipRef.querySelector('.tooltip-inner');

        tooltipContent.innerHTML = tooltip.body
            .map(VisualizationChartBehaviour.tooltipBodyItemContentArrayToString)// convert each items contentArray to a single string
            .reduce(VisualizationChartBehaviour.tooltipHtmlReducerFactory(tooltip), ''); // convert this array to one single string of html

        // retrieve the bounding rectangle of the canvas
        const position = context.chart.canvas.getBoundingClientRect();

        // Display, position, and set styles for font
        tooltipRef.style.opacity = '1';
        tooltipRef.style.position = 'absolute';
        tooltipRef.style.left = position.left + window.pageXOffset + tooltip.caretX + 'px';
        tooltipRef.style.top = position.top + window.pageYOffset + tooltip.caretY + 'px';
        tooltipRef.style.fontFamily = tooltip._bodyFontFamily;
        tooltipRef.style.fontSize = tooltip.bodyFontSize + 'px';
        tooltipRef.style.fontStyle = tooltip._bodyFontStyle;
        tooltipRef.style.padding = tooltip.yPadding + 'px ' + tooltip.xPadding + 'px';
        tooltipRef.style.pointerEvents = 'none';
    }

    static tooltipBodyItemContentArrayToString(bodyItem) {
        return bodyItem.lines.reduce(function(output, value) {
            return output + value
        }, '');
    }

    static tooltipHtmlReducerFactory(tooltip) {
        return (output, value, index) => {
            let entry = '<li class="legend__item tooltip-inner">';
            entry += '  <span class="legend__dot" style="background-color: ' + tooltip.labelColors[index].backgroundColor + ';"></span>';
            entry += '  <span class="legend__value">' + value + '</span>';
            entry += '</li>';
            return output + entry;
        };
    }

    /**
     * Remove disallowed characters and replace them
     * by compatible ones
     */
    static sanitize(value) {
        value = value.toLowerCase();
        value = value.replace(/ä/g, 'ae');
        value = value.replace(/ö/g, 'oe');
        value = value.replace(/ü/g, 'ue');
        value = value.replace(/ß/g, 'ss');
        value = value.replace(/ /g, '-');
        value = value.replace(/\./g, '');
        value = value.replace(/,/g, '');
        value = value.replace(/\(/g, '');
        value = value.replace(/\)/g, '');
        return value;
    }

    /**
     * Check predefined conditions if a workaround for tooltips is required
     * @returns {boolean}
     */
    static requiresTooltipWorkaround() {
        return document.body.classList.contains('firefox') && document.body.classList.contains('mac');
    }

    /**
     * Constructs a label consisting of the data label, data value and data unit
     */
    customLabelFactory(context) {
        // return string here
        const index = context.dataIndex;
        const label = this.chart.data.labels[index];
        const value = context.dataset.data[index];

        return `${label}: ${value} ${context.chart.behaviour.unit || ''}`;
    }

    getLabelAndValueUnderCursor(event) {

        const points = this.chart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, true);

        if (!points.length) {
            return null;
        }

        const firstPoint = points[0];
        const label = this.chart.data.labels[firstPoint.index];
        const value = this.chart.data.datasets[firstPoint.datasetIndex].data[firstPoint.index];

        return { label: label, value: value };
    }

    /**
     * Registers the workaround for buggy tooltips
     */
    registerTooltipWorkaround() {
        this.canvas.onmousemove = (e) => {
            const elementData = this.getLabelAndValueUnderCursor(e);

            // if there is no element data no element is under the cursor
            if (!elementData)
                return;

            const infoText = document.querySelector('#visualization-chart-' + this.id  +'__context-info');
            // assemble tooltip label and set it on the element below the canvas
            infoText.text(elementData.label + ': ' + elementData.value + ' ' + this.unit);
        };

        // empty the element below the canvas when the cursor leaves the element and set it to
        // a placeholder to avoid it changing the height
        this.canvas.onmouseleave = () => {
            const infoText = document.querySelector('#visualization-chart-' + this.id  +'__context-info');
            infoText.innerHtml = '&nbsp;';
        }
    }

    /**
     * Registers the click handler on the canvas that expands
     * the collapsed detail sections.
     */
    registerCanvasClickHandler() {
        this.canvas.onclick = (e) => {
            const elementData = this.getLabelAndValueUnderCursor(e);

            // if there is no element data no element is under the cursor
            if (!elementData)
                return;

            // construct the label id of the detail content
            const labelId = "#" + VisualizationChartBehaviour.sanitize(elementData.label) + '-' + this.id;
            const label = document.querySelector(labelId);

            // check if the last clicked label is identical to the current one
            if (this.labelLastClicked !== label) {
                // remove the show class of the last label if set
                if (this.labelLastClicked !== null)
                    this.labelLastClicked.classList.remove('show');
                // add the show class to the current label
                label.classList.add('show');
            }
            // if it is the same level, check if it has 'shown' on it
            else if (!label.classList.contains('show')) {
                // add if it does not have that class
                label.classList.add('show');
            } else {
                // otherwise remove it to be able to toggle the details
                label.classList.remove('show');
            }

            // store the current label as the last clicked label
            this.labelLastClicked = label;
        }
    }
}

