// ============ IMPORTS =============
import '../styles/app.scss';

// import sal from 'sal.js';
import DomUtils from "./dom-utils";
import initModalFlags from './modal-flags';
import Velocity from 'velocity-animate';
import smoothScroll from './smooth-scroll';
import {
    enableBootstrapCollapse,
    enableBootstrapDropdowns,
    // enableBootstrapScrollspy,
    // enableBootstrapTabs,
    // enableLazyLoad,
    enableLightbox,
    enableScrollCheck,
    enableTinySliders,
    enableParallaxImages,
    enableToplink
} from "./feature-initializers";
import {enableMasonry} from "./faq_masonry";
window.DomUtils = DomUtils;
import VisualizationChartBehaviour from "./custom-elements/visualization-chart";

// ============ MANIFEST ============
// automatically add all images to the manifest.json
const imagesCtx = require.context('../images', true, /\.(png|jpg|jpeg|gif|ico|svg|webp)$/);
imagesCtx.keys().forEach(imagesCtx);

// ============ REQUIRES ============
const hcSticky = require('hc-sticky');
window.Velocity = Velocity;

// ============ VARIABLES ===========
window.location.document = function () {
    const pathParts = window.location.pathname.split('/');
    return pathParts[pathParts.length - 1];
};


function initVisualizationCharts() {
    window.visualizationChartOptions  = window.visualizationChartOptions  || [];

    if (window.visualizationChartOptions.length < 1)
        return;

    for (const optionSet of window.visualizationChartOptions ) {
        new VisualizationChartBehaviour(optionSet.id, optionSet.labels, optionSet.data, optionSet.colorsArray, optionSet.unit);
    }
}

function checkCaptcha() {
    const hcaptcha = document.querySelector('textarea[name="h-captcha-response"]');
    if(hcaptcha) {
        hcaptcha.setAttribute("required", "required");
    }
}

enableLightbox();
enableTinySliders();
enableScrollCheck();
enableBootstrapDropdowns();
enableBootstrapCollapse();
enableToplink();
enableParallaxImages();
initModalFlags();
enableMasonry();

document.addEventListener('DOMContentLoaded', () => {
    initVisualizationCharts();
    // initSAL();

    const Sticky = new hcSticky('.top-navbar', {
        stickTo: 'body'
    });

    window.scrollY = 0;
    setTimeout(function() {
        if (window.location.hash) {
            if (window.location.hash.indexOf('collapse') === -1) {
                smoothScroll(window.location.hash);
            } else {
                $(window.location.hash).collapse('show');
            }

        }
    });
});

//load check captcha with delay
setTimeout(function() {
    checkCaptcha();
}, 2500);
