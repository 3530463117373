import Modal from "bootstrap/js/src/modal";

export default function initModalFlags() {
    const noPeekUntil = new Date(Date.parse(localStorage.getItem("peek-on-load")) || Date.now());
    let newNoPeekUntil = new Date();
    newNoPeekUntil.setDate(noPeekUntil.getDate() + 1);
    const now = new Date(Date.now());

    document.querySelectorAll('.modal-flags__modal').forEach((modal) => {
        let bsModal = new Modal(modal);

        if (modal.classList.contains('peek-on-load') && noPeekUntil < now) {
            bsModal.show();

            setTimeout(() => {
                bsModal.hide();
            }, 6000);
        }
    })
}
