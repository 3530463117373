export function enableLightbox(selector = 'a[data-lightbox]') {
    if (document.querySelectorAll(selector).length > 0) {
        import('./lightbox').then(({default: Lightbox}) => {
            new Lightbox();
        }).catch(reason => {
            console.warn('Lightbox load has failed!', reason);
        });
    }
}

export function enableBootstrapDropdowns(selector = '[data-bs-toggle="dropdown"]') {
    const dropdownElements = document.querySelectorAll('[data-bs-toggle="dropdown"]');
    if (dropdownElements.length > 0) {
        import('bootstrap/js/src/dropdown').then(({default: Dropdown}) => {
            Array.from(dropdownElements)
                .forEach((elem) => {
                    new Dropdown(elem);
                });
        });
    }
}

export function enableBootstrapCollapse(selector = '.collapse') {
    const collapseElements = document.querySelectorAll(selector);
    if (collapseElements.length > 0) {
        import('bootstrap/js/src/collapse').then(({default: Collapse}) => {
            Array.from(collapseElements)
                .forEach((elem) => {
                    new Collapse(elem, {toggle: false});
                });
        });
    }
}

export function enableBootstrapTabs(selector = '[data-bs-toggle="tab"]') {
    const tabElements = document.querySelectorAll(selector);
    if (tabElements.length > 0) {
        import('bootstrap/js/src/tab').then(({default: Tab}) => {
            Array.from(tabElements)
                .forEach((elem) => {
                    new Tab(elem);
                });
        });
    }
}

export function enableBootstrapScrollspy(selector = '[data-bs-spy="scroll"]') {
    const scrollspyElements = document.querySelectorAll('[data-bs-spy="scroll"]');
    if (scrollspyElements.length > 0) {
        import('bootstrap/js/src/scrollspy').then(({default: Scrollspy}) => {
            Array.from(scrollspyElements)
                .forEach((elem) => {
                    new Scrollspy(elem);
                });
        });
    }
}

export function enableTinySliders(selector = '.tiny-slider') {
    const tinySliders = document.querySelectorAll(selector);
    if (tinySliders.length > 0 || window.tinySliderSettings || window.memorialSliderOptions) {
        import('./tiny-slider').then(({default: initTinySlider}) => {
            initTinySlider();
        }).catch(reason => {
            console.warn('TinySlider load has failed!', reason);
        });
    }
}

export function enableToplink(selector = '.ce_toplink') {
    if (document.querySelectorAll(selector).length > 0) {
        import('./top-link').then(({default: TopLink}) => {
            new TopLink();
        });
    }
}

export function enableParallaxImages(selector = '.img-parallax') {
    if (document.querySelectorAll(selector).length > 0) {
        import('./parallax').then(({default: Parallax}) => {
            new Parallax();
        });
    }
}

export function enableLazyLoad(selector = 'img.lazy') {
    if (document.querySelectorAll(selector).length > 0) {
        import("vanilla-lazyload/dist/lazyload.esm").then(({default: LazyLoad}) => {
            new LazyLoad({
                elements_selector: selector,
                callback_error: (p) => console.error('LazyLoad error:', p),
            });
        })
    }
}

export function enableScrollCheck() {
    window.addEventListener('scroll', () => {
        // use Math.round because activating the scaling feature
        // of the browser turns the page offset from integer numbers
        // to float numbers. Yay for JavaScript missing real types :|
        if (Math.round(window.pageYOffset) > 25) {
            document.body.classList.add('js-scrolled');
        }
        else {
            document.body.classList.remove('js-scrolled');
        }
    }, {passive: true});
}
