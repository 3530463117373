/*
 * @package    App
 * @author     Florian Vick <fvick@rapid-data.de>
 * @copyright  2020 Rapid Data GmbH. All rights reserved.
 * @license    MIT
 * @filesource
 *
 */
export default class DomUtils {
    /**
     * Get the closest matching element up the DOM tree.
     * @param  {Node & ParentNode} elem     Starting element
     * @param  {String}  selector Selector to match against
     * @return {Node&ParentNode}  Returns null if not match found
     */
    static getClosest ( elem, selector ) {

        // Element.matches() polyfill
        this.registerMatchesPolyfill();

        // Get closest match
        for ( ; elem && elem !== document; elem = elem.parentNode ) {
            if ( elem.matches( selector ) ) return elem;
        }

        return null;

    };

    /**
     * Get all of an element's parent elements up the DOM tree
     * @param  {Node}   elem     The element
     * @param  {String} selector Selector to match against [optional]
     * @return {Array}           The parent elements
     */
    static getParents ( elem, selector ) {

        this.registerMatchesPolyfill();

        // Setup parents array
        const parents = [];

        // Get matching parent elements
        for ( ; elem && elem !== document; elem = elem.parentNode ) {

            // Add matching parents to array
            if ( selector ) {
                if ( elem.matches( selector ) ) {
                    parents.push( elem );
                }
            } else {
                parents.push( elem );
            }

        }

        return parents;

    };

    /**
     * Get all of an element's parent elements up the DOM tree until a matching parent is found
     * @param  {Node}   elem     The element
     * @param  {String} parent   The selector for the parent to stop at
     * @param  {String} selector The selector to filter against [optionals]
     * @return {Array}           The parent elements
     */
    static getParentsUntil ( elem, parent, selector ) {

        // Element.matches() polyfill
        this.registerMatchesPolyfill();

        // Setup parents array
        const parents = [];

        // Get matching parent elements
        for ( ; elem && elem !== document; elem = elem.parentNode ) {

            if ( parent ) {
                if ( elem.matches( parent ) ) break;
            }

            if ( selector ) {
                if ( elem.matches( selector ) ) {
                    parents.push( elem );
                }
                break;
            }

            parents.push( elem );

        }

        return parents;

    };

    static parseHtml(str) {
        const tmpDoc = document.implementation.createHTMLDocument();
        tmpDoc.body.innerHTML = str;
        return tmpDoc.body.children;
    };


    static registerMatchesPolyfill() {
        // Element.matches() polyfill
        if (!Element.prototype.matches) {
            Element.prototype.matches =
                Element.prototype.matchesSelector ||
                Element.prototype.mozMatchesSelector ||
                Element.prototype.msMatchesSelector ||
                Element.prototype.oMatchesSelector ||
                Element.prototype.webkitMatchesSelector ||
                function(s) {
                    let matches = (this.document || this.ownerDocument).querySelectorAll(s),
                        i = matches.length;
                    while (--i >= 0 && matches.item(i) !== this) {}
                    return i > -1;
                };
        }
    }
}
window.DomUtils = DomUtils;