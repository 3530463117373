export function enableMasonry(selector = 'masonry-layout') {
    if (document.querySelectorAll('masonry-layout').length > 0) {
        import('@appnest/masonry-layout').then(({default: MasonryLayout}) => {
            const bpObserver = window.matchMedia('(max-width: 1280px)');
            if (bpObserver.matches) {
                document.querySelectorAll('masonry-layout').forEach((masonry) => {
                    masonry.cols = 'auto';
                });
            }

            bpObserver.onchange = (ev => {
                document.querySelectorAll('masonry-layout').forEach((masonry) => {
                    masonry.cols = ev.matches ? 'auto' : 3;
                });
            });

            // masonry faq
            document.querySelectorAll('.faqs .faqs__faq-footer').forEach((element) => {
                element.addEventListener('click', evt => {
                    const cardBody = element.parentElement.querySelector('.faqs__faq-body');
                    if (!cardBody) {
                        return;
                    }
                    cardBody.classList.toggle('faqs__faq-body--open');

                    if (cardBody.classList.contains('faqs__faq-body--open')) {
                        element.children.item(1).textContent = 'Einklappen';
                    } else {
                        element.children.item(1).textContent = 'Weiterlesen';
                    }
                })
            });
        });
    }
}
