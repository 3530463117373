/*
 * @package    App
 * @author     Florian Vick <fvick@rapid-data.de>
 * @copyright  2020 Rapid Data GmbH. All rights reserved.
 * @license    MIT
 * @filesource
 */
import DomUtils from "./dom-utils";
import Velocity from "velocity-animate";

export default function smoothScroll(targetSelector, offset = undefined, callback = undefined) {
    let target = document.querySelector(targetSelector);
    if (document.querySelector('#navigation').clientHeight === null) {
        offset = 0;
    } else {
        offset = document.querySelector('#navigation').clientHeight;
    }

    if (target === null) {
        console.error('[SmoothScroll] The element I shall scroll to does not exist!', targetSelector);
    }

    Velocity(target ? target : document.body,"scroll", {
        offset: -offset,
        duration: 1000,
        complete: callback
    });
}
